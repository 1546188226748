import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { api } from "../../services/api";

import { RiCloseFill } from 'react-icons/ri'
import iconApprovals from '../../assets/icon_approvalsModal.svg'
import { useProduct } from '../../providers/product'
import Spinner from '../spinner';
import moment from 'moment'

import { Toast } from '../../utils/consts';
import { handleFieldChange } from '../../utils/form';

import styles from "./styles.module.css";

export default function QuestionsSaude(props) {
    const navigate = useNavigate()

    const { setOpenModalChecklist } = useProduct()

    const date = new Date();
    const futureDate = date.getDate()
    date.setDate(futureDate);
    const defaultValue = date.toLocaleDateString('en-CA');

    const [evaluationDate, setEvaluationDate] = useState(defaultValue)
    const [evaluatorPosition, setEvaluatorPosition] = useState([])
    const [potentialCarcinogenicity, setPotentialCarcinogenicity] = useState([])
    const [productExposure, setProductExposure] = useState([])
    const [potentialRisk, setPotentialRisk] = useState([])
    const [biologicalMarker, setBiologicalMarker] = useState([])
    const [antidote, setAntidote] = useState([])
    const [recommendations, setRecommendations] = useState([])
    const [justification, setJustification] = useState('')
    const [restriction, setRestriction] = useState('')

    const [evaluatorName, setEvaluatorName] = useState(props.name)
    const [evaluatorId, setEvaluatorId] = useState(props.id)
    const [productId, setProductId] = useState(props.productId)

    const [evaluation, setEvaluation] = useState([])
    const [loading, setLoading] = useState([])

    function handleCloseModal() {
        setOpenModalChecklist(false)
    }
    let validated = []

    function validation() {

        validated = true
        if (evaluatorPosition == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir cargo do avaliador antes de submeter.`
            })
            validated = false
            return false
        }
        if (potentialCarcinogenicity == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se existe potencial de Carcinogenicidade, Teratogenicidade ou Mutagenicidade antes de submeter.`
            })
            validated = false
            return false
        }
        if (productExposure == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir quais as rotas de exposição do produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (potentialRisk == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se existe potencial de risco de intoxicação aguda e/ou crônica antes de submeter.`
            })
            validated = false
            return false
        }
        if (biologicalMarker == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se há marcador bilógico de exposição antes de submeter.`
            })
            validated = false
            return false
        }
        if (antidote == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir se há algum antídoto para o caso de intoxicação antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluation == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma avaliação antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluation == 'Reprovado' && justification == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir uma justificativa antes de submeter.`
            })
            validated = false
            return false
        }
        if (evaluation == 'Aprovado com restrições' && restriction == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir as restrições antes de submeter.`
            })
            validated = false
            return false
        }
        
        if (recommendations == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir as recomendações antes de submeter.`
            })
            validated = false
            return false
        }
        if (props.productId == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir id do produto antes de submeter.`
            })
            validated = false
            return false
        }
        if (props.id == '') {
            Toast.fire({
                icon: 'error',
                title: `Inclusão inválida! É necessário inserir id antes de submeter.`
            })
            validated = false
            return false
        }
    }

    async function handleSubmit() {
        validation();
        if (validated == false) {
            return false
        }
        try {
            setLoading(true)
            let response = await api.post(`health/checklist`, {
                "createdBy": evaluatorId,
                "evaluationDate": moment(evaluationDate).format('YYYY-MM-DD'),
                "evaluatorPosition": evaluatorPosition,
                "potentialCarcinogenicity": potentialCarcinogenicity,
                "productExposure": productExposure,
                "potentialRisk": potentialRisk,
                "biologicalMarker": biologicalMarker,
                "antidote": antidote,
                "evaluation": evaluation,
                "productId": productId,
                "justification": justification,
                "restriction": restriction,
                "recommendations": recommendations,
            })

            if (response.status == 200) {
                Swal.fire({
                    title: 'Avaliação enviada !',
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonColor: '#009EBE',
                    confirmButtonText: 'Ok'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate('/products')
                        setOpenModalChecklist(false)
                    }
                })

            }
        } catch (error) {
            Toast.fire({
                icon: 'error',
                title: `${error.response.data}`
            })
        }
        setLoading(false)
    }

    return (
        <>
            {loading == true ? (
                <div className="spinner">
                    <Spinner animation="border" />
                </div>
            ) : (
                <div></div>)}
            <div className={styles.main}>
                <div className={styles.title}>
                    <div style={{ display: 'flex', alignItems: 'center' }}><img alt='approvals' src={iconApprovals} /> <h1>Avaliação de Saúde ocupacional / Higiene</h1></div>
                    <h2 onClick={handleCloseModal} style={{ fontSize: '150%', cursor: 'pointer' }}><RiCloseFill color='#fff' /></h2>
                </div>
                <div className={styles.mainAvaliação}>
                    <div className={styles.questions}>
                        <div style={{ display: 'flex' }} className={styles.divQuestions}>
                            <div style={{ width: '80%', marginRight: '0.5rem' }}>
                                <h2>Nome do avaliador:</h2>
                                <input style={{ width: '95%' }} disabled type='text' value={evaluatorName} onChange={(e) => setEvaluatorName(e.target.value)} />
                            </div>
                            <div style={{ width: '31%' }}>
                                <h2>Data da avaliação:</h2>
                                <input disabled type='date' value={evaluationDate} onChange={(e) => setEvaluationDate(e.target.value)} />
                            </div>
                        </div>

                        <div className={styles.divQuestions}>
                            <h2>Cargo do avaliador:</h2>
                            <input 
                                type='text' 
                                value={evaluatorPosition} 
                                onChange={(event) => 
                                    handleFieldChange(
                                        event, 
                                        setEvaluatorPosition
                                    )
                                } 
                            />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Existe potencial de Carcinogenicidade, Teratogenicidade ou Mutagenicidade?</h2>
                            <input type="radio" id="truePotentialCarcinogenicity" onChange={(e) => setPotentialCarcinogenicity(e.target.value)} name="storageLocation" value="true" />
                            <label for="truePotentialCarcinogenicity">Sim</label>
                            <input type="radio" id="falsePotentialCarcinogenicity" onChange={(e) => setPotentialCarcinogenicity(e.target.value)} name="storageLocation" value="false" />
                            <label for="falsePotentialCarcinogenicity">Não</label>
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Quais as Rotas de Exposição do Produto?</h2>
                            <input 
                                type='text' 
                                value={productExposure} 
                                onChange={(event) => 
                                    handleFieldChange(
                                        event, 
                                        setProductExposure
                                    )
                                } 
                            />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Existe potencial de risco de intoxicação aguda e/ou crônica? Quais os principais órgãos alvo? </h2>
                            <input 
                                type='text' 
                                value={potentialRisk} 
                                onChange={(event) => 
                                    handleFieldChange(
                                        event, 
                                        setPotentialRisk
                                    )
                                } 
                            />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Marcador Bilógico de Exposição? Sua realização é exigência legal?  </h2>
                            <input 
                                type='text' 
                                value={biologicalMarker} 
                                onChange={(event) => 
                                    handleFieldChange(
                                        event, 
                                        setBiologicalMarker
                                    )
                                } 
                            />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Há algum Antídoto para o caso de Intoxicação? </h2>
                            <input 
                                type='text' 
                                value={antidote} 
                                onChange={(event) => 
                                    handleFieldChange(
                                        event, 
                                        setAntidote
                                    )
                                } 
                            />
                        </div>
                        <div className={styles.divQuestions}>
                            <h2>Recomendações:</h2>
                            <input type='text' value={recommendations} onChange={(e) => setRecommendations(e.target.value)} />
                        </div>
                    </div>
                    <div className={styles.answers}>
                        <h1>Avaliação </h1>
                        <div className={styles.containerAnswers}>
                            <div className={styles.divAnswers}>
                                <input value="Aprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovado" name="Avaliação" />
                                <label for="aprovado">Aprovado</label>
                            </div>
                            <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                                <div style={{ display: 'flex' }}>
                                    <input value="Reprovado" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="reprovado" name="Avaliação" />
                                    <label for="reprovado">Reprovado</label>
                                </div>
                                {evaluation == 'Reprovado' ?
                                    <div style={{ marginTop: '0.5rem' }}>
                                        <textarea 
                                            onChange={(event) => 
                                                handleFieldChange(
                                                    event, 
                                                    setJustification
                                                )
                                            } 
                                            value={justification}
                                            style={{ height: '60px' }} 
                                            placeholder="Justificativa" 
                                        />
                                    </div>
                                    : null}
                            </div>
                            <div style={{ flexDirection: 'column' }} className={styles.divAnswers}>
                                <div style={{ display: 'flex' }}>
                                    <input value="Aprovado com restrições" onChange={(e) => setEvaluation(e.target.value)} type="radio" id="aprovadoCondições" name="Avaliação" />
                                    <label for="aprovadoCondições">Aprovado com restrições</label>
                                </div>
                                {evaluation == 'Aprovado com restrições' ?
                                    <div style={{ marginTop: '0.5rem' }}>
                                        <textarea 
                                            onChange={(event) => 
                                                handleFieldChange(
                                                    event, 
                                                    setRestriction
                                                )
                                            } 
                                            value={restriction}
                                            style={{ height: '60px' }} 
                                            placeholder="Restrições" 
                                        />
                                    </div>
                                    : null}
                            </div>

                        </div>
                    </div>
                </div>
                <div className={styles.divButton}>
                    <button  onClick={() => handleSubmit()}>Confirmar</button>
                </div>
            </div>
        </>
    )
}