import { charLimit, Toast } from "./consts";

export function verifyCharLimit(value) {
  if (value.length <= charLimit) {
    return false;
  }

  Toast.fire({
    icon: "warning",
    title: `Limite de caracteres atingido!`,
  });
}

export function handleFieldChange(event, setState) {
  verifyCharLimit(event.target.value);
  setState(event.target.value.slice(0, charLimit));
}
